import * as React from 'react';
import {
  List,
  ListItem,
  SelectOptionObject,
  Title
} from '@patternfly/react-core';

interface ICustomerFlowReviewProps {
  category: string | SelectOptionObject;
  subCategories: (string | SelectOptionObject)[];
  fileUploadValue: string | File | undefined;
  fileName: string;
}

export const CustomerFlowReview: React.FunctionComponent<ICustomerFlowReviewProps> = ({
  category,
  subCategories,
  fileName
}) => {
  return (
    <React.Fragment>
      <Title headingLevel="h2">Review</Title>
      <List className="pf-u-mb-md">
        <ListItem>Category: {category}</ListItem>
        <ListItem>Subcategories: {subCategories.length ? subCategories.join(', ') : 'none'}</ListItem>
        <ListItem>File upload: {fileName === '' ? 'none' : fileName}</ListItem>
      </List>
    </React.Fragment>
  );

}
