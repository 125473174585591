import * as React from 'react';
import { FileUpload, Form } from '@patternfly/react-core';

interface IFileSelectorProps {
  fileUploadValue: string | File | undefined;
  setFileUploadValue: React.Dispatch<React.SetStateAction<string | File | undefined>>;
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
}

export const FileSelector: React.FunctionComponent<IFileSelectorProps> = ({
  fileUploadValue,
  setFileUploadValue,
  fileName,
  setFileName
}) => {

  const handleFileChange = (value: string | File, filename: string) => {
    setFileUploadValue(value);
    setFileName(filename);

    const formData = new FormData(); // {}
    formData.append('userUpload', value, filename)
    // console.log(formData.getAll('userUpload'));

    fetch('http://localhost:8080/saveImage', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        console.log(data.path)
      })
      .catch(error => {
        console.error(error)
      });


  };
  return (
    <Form encType="multipart/form-data">
      <FileUpload
        id="file-upload"
        value={fileUploadValue}
        filename={fileName}
        onChange={handleFileChange} />
    </Form>
  )
}
