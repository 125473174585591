import * as React from 'react';
import {
  Wizard,
  WizardStep,
  SelectOptionObject,
  WizardStepFunctionType
} from '@patternfly/react-core';
import { CategorySelector } from '@app/components/CategorySelector/CategorySelector';
import { SubCategorySelector } from '@app/components/SubCategorySelector/SubCategorySelector';
import { FileSelector } from '@app/components/FileSelector/FileSelector';
import { CustomerFlowReview } from '@app/components/CustomerFlowReview/CustomerFlowReview';

export const CustomerFlow: React.FunctionComponent<React.HTMLProps<HTMLDivElement>> = () => {
  const [category, setCategory] = React.useState<string | SelectOptionObject>('');
  const [subCategories, setSubcategories] = React.useState<(string | SelectOptionObject)[]>([]);
  const [fileUploadValue, setFileUploadValue] = React.useState<string | File | undefined>(undefined);
  const [fileName, setFileName] = React.useState<string>('');
  const [stepIdReached, setStepIdReached] = React.useState<React.ReactText>(1);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [customerFlowFormValue, setCustomerFlowFormValue] = React.useState<string | SelectOptionObject>('');
  const [allStepsValid, setAllStepsValid] = React.useState(false);
  React.useEffect(() => {
    setSubcategories([]);
  }, [category, setCategory]);

  const areAllStepsValid = React.useCallback(() => {
    setAllStepsValid(isFormValid);
  }, [setAllStepsValid, isFormValid]);

  const onFormChange = (isValid: boolean, value: string | SelectOptionObject) => {
    setIsFormValid(isValid);
    setCustomerFlowFormValue(value);
    areAllStepsValid();
  }

  const steps: WizardStep[] = [
    {
      name: 'Category',
      id: 1,
      enableNext: allStepsValid,
      component: (<>
        <CategorySelector
          onChange={onFormChange}
          isFormValid={isFormValid}
          setCategory={setCategory} />
      </>),
      hideCancelButton: true
    },
    {
      name: 'Sub-category',
      id: 2,
      canJumpTo: stepIdReached >= 2,
      component: (<>
        <SubCategorySelector
          subCategories={subCategories}
          category={category}
          setSubcategories={setSubcategories} />
      </>),
      hideCancelButton: true
    },
    {
      name: 'File Upload',
      id: 3,
      canJumpTo: stepIdReached >= 3,
      component: <FileSelector
        fileUploadValue={fileUploadValue}
        setFileUploadValue={setFileUploadValue}
        fileName={fileName}
        setFileName={setFileName} />,
      hideCancelButton: true
    },
    {
      name: 'Review',
      id: 4,
      canJumpTo: stepIdReached >= 4,
      component: <CustomerFlowReview
        category={category}
        subCategories={subCategories}
        fileUploadValue={fileUploadValue}
        fileName={fileName}
      />,
      nextButtonText: 'Finish',
      hideCancelButton: true
    }
  ];

  React.useEffect(() => {
    areAllStepsValid();
  }, [isFormValid, areAllStepsValid]);

  const onNext: WizardStepFunctionType = ({ id }) => {
    if (id) {
      setStepIdReached(stepIdReached < id ? id : stepIdReached);
    }
    areAllStepsValid();
  };

  return (
    <React.Fragment>
      <Wizard
        hideClose={true}
        onClose={() => {
          alert(JSON.stringify({
            category,
            subCategories,
            fileUploadValue
          }, null, 2));
          console.log(customerFlowFormValue);
        }}
        height={'90%'}
        title="Enter information"
        description="Add some details about your problem."
        steps={steps}
        startAtStep={1}
        onNext={onNext}
      />
    </React.Fragment>
  );

}
