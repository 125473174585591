import * as React from 'react';

interface IChromeProps {
  subHeader?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export const Chrome: React.FunctionComponent<IChromeProps> = (props: IChromeProps) => (
  <>
    {props.subHeader}
    {props.children}
    {props.footer}
  </>
);
