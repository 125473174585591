import * as React from 'react';
import {
  Page,
  PageHeader,
  PageHeaderTools,
  SkipToContent,
  Nav,
  NavList,
  NavItem,
  PageHeaderToolsItem,
  Button
} from '@patternfly/react-core';
import { FacebookIcon, InstagramIcon } from '@patternfly/react-icons';
import { NavLink, useHistory } from 'react-router-dom';
import { routes, IAppRoute } from '@app/routes';
import logo from '@app/assets/img/dark-theme-logo.png';

interface IAppLayout {
  children: React.ReactNode;
}

const AppLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {
  // const logoProps = {
  //   href: '/',
  //   target: '_blank'
  // };
  // const [isNavOpen, setIsNavOpen] = React.useState(true);
  // const [isMobileView, setIsMobileView] = React.useState(true);
  // const [isNavOpenMobile, setIsNavOpenMobile] = React.useState(false);
  // const onNavToggleMobile = () => {
  //   setIsNavOpenMobile(!isNavOpenMobile);
  // };
  // const onNavToggle = () => {
  //   setIsNavOpen(!isNavOpen);
  // }
  // const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
  //   setIsMobileView(props.mobileView);
  // };

  function LogoImg() {
    const history = useHistory();
    function handleClick() {
      history.push('/');
    }
    return <img src={logo} style={{maxHeight: '44px'}} className="pf-u-p-sm" onClick={handleClick} />;
  }

  // const history = useHistory();

  const [activeItem, setActiveItem] = React.useState(0);

  const onSelect = (result) => {
    setActiveItem(result.itemId);
  }

  const renderNavItem = (route: IAppRoute, index: number) => (
    <NavItem key={`${route.label}-${index}`} id={`${route.label}-${index}`}>
      <NavLink exact={route.exact} to={route.path} activeClassName="pf-m-current">
        {route.label}
      </NavLink>
    </NavItem>
  );

  const Navigation = (
    <Nav onSelect={onSelect} id="nav-primary-simple" theme="dark" variant="horizontal">
      <NavList id="nav-list-simple">
        {routes.map((route, idx) => route.label && renderNavItem(route, idx))}
      </NavList>
    </Nav>
  );

  // const Header = (
  //   <PageHeader
  //     logo="TiZech"
  //     logoProps={logoProps}
  //     headerTools={
  //       <PageHeaderTools>
  //         <a href="tel:+19198850777" target="_blank" rel="noreferrer" aria-disabled="false" className="pf-c-button pf-m-link">
  //           <PhoneAltIcon />
  //         </a>
  //       </PageHeaderTools>
  //     }
  //     showNavToggle={false}
  //     isNavOpen={isNavOpen}
  //     onNavToggle={isMobileView ? onNavToggleMobile : onNavToggle}
  //   />
  // );

  const Tools = (
    <PageHeaderTools className="rm-social-container">
      <PageHeaderToolsItem>
        <Button className="pf-u-mt-sm" variant="link" icon={<FacebookIcon style={{ fontSize: '1.5em', color: '#fff' }} />} onClick={() => {
          window.location.href = 'https://www.facebook.com/TizechService/';
        }} />
      </PageHeaderToolsItem>
      <PageHeaderToolsItem>
        <Button className="pf-u-mt-sm" style={{color: '#fff'}} variant="link" onClick={() => {
          window.location.href = 'https://www.instagram.com/tizechservice/';
        }}>
          <InstagramIcon style={{ fontSize: '1.5em' }} />
        </Button>
      </PageHeaderToolsItem>
    </PageHeaderTools>
  );

  const Header = (<PageHeader headerTools={Tools} logo={<LogoImg />} topNav={Navigation} />);

  const PageSkipToContent = (
    <SkipToContent href="#primary-app-container">
      Skip to Content
    </SkipToContent>
  );
  return (
    <Page
      mainContainerId="primary-app-container"
      header={Header}
      // onPageResize={onPageResize}
      skipToContent={PageSkipToContent}>
      {children}
    </Page>
  );
}

export { AppLayout };
