import * as React from 'react';
import {
  TextContent,
  Text,
  Button,
  PageSection,
  Title,
  Grid,
  GridItem,
  Gallery,
  GalleryItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle
} from '@patternfly/react-core';

import { Chrome } from '@app/AppLayout/Chrome';
import { Footer } from '@app/AppLayout/Footer';

import commercialElectronicsImg from '@app/assets/img/commercial-electronics-install-service.jpg';
import homeAudioImg from '@app/assets/img/home-audio-install-service.jpg';
import homeAutomationImg from '@app/assets/img/home-automation-install-service.jpg';
import homeTheaterImg from '@app/assets/img/home-theater-install-service.jpg';
import homeVideoImg from '@app/assets/img/home-video-install-service.jpg';
import techDesignImg from '@app/assets/img/tech-design-installation-service.jpg';

const Dashboard: React.FunctionComponent<{}> = () => {

  const commercialAltTxt = `Commercial Electronics`;
  const homeAudioAltTxt = `Home Audio`;
  const homeAutomationAltTxt = `Home automation`;
  const homeTheaterAltTxt = `Home Theater`;
  const homeVideoAltTxt = `Home video`;

  return (
    <Chrome footer={<Footer />}>
      <PageSection>

        <Gallery hasGutter>

          <GalleryItem>
            <Card>
              <CardHeader>
                <img src={homeAudioImg} alt={homeAudioAltTxt} />
              </CardHeader>
              <CardTitle className="pf-u-text-align-center">Home audio</CardTitle>
              <CardBody>
                Listen to your favorite music like you have never heard it before, our state-of-the-art audio system are designed to take your entertainment experience to the next level.
              </CardBody>
            </Card>
          </GalleryItem>

          <GalleryItem>
            <Card>
              <CardHeader>
                <img src={homeVideoImg} alt={homeVideoAltTxt} />
              </CardHeader>
              <CardTitle className="pf-u-text-align-center">Home video</CardTitle>
              <CardBody>
                We offer a wide selection of high quality video product lines. And, we have qualified installers that will perform custom installations at your chosen location.
              </CardBody>
            </Card>
          </GalleryItem>

          <GalleryItem>
            <Card>
              <CardHeader>
                <img src={homeTheaterImg} alt={homeTheaterAltTxt} />
              </CardHeader>
              <CardTitle className="pf-u-text-align-center">Home theater</CardTitle>
              <CardBody>
                How would you like to have your very own movie theater style cinema experience, in the comfort of your own home? No more crowds. No more disturbances.
              </CardBody>
            </Card>
          </GalleryItem>

          <GalleryItem>
            <Card>
              <CardHeader>
                <img src={homeAutomationImg} alt={homeAutomationAltTxt} />
              </CardHeader>
              <CardTitle className="pf-u-text-align-center">Home automation</CardTitle>
              <CardBody>
                Ever wonder why your lighting, stereo, television, climate control, security and other electronic home components don’t work together? Well, now they can.
              </CardBody>
            </Card>
          </GalleryItem>

          <GalleryItem>
            <Card>
              <CardHeader>
                <img src={commercialElectronicsImg} alt={commercialAltTxt} />
              </CardHeader>
              <CardTitle className="pf-u-text-align-center">Commercial electronics</CardTitle>
              <CardBody>
                Since mounted flat screen tv’s have dropped in price, there is no excuse to not use them for signage, instructions, menus and as multi-sports/event displays everywhere.
              </CardBody>
            </Card>
          </GalleryItem>

          <GalleryItem>
            <Card>
              <CardHeader>
                <img src={techDesignImg} alt={commercialAltTxt} />
              </CardHeader>
              <CardTitle className="pf-u-text-align-center">Tech design</CardTitle>
              <CardBody>
                We provide a wide range of A/V consulting, installation, and design services.
              </CardBody>
            </Card>
          </GalleryItem>
        </Gallery>

      </PageSection>

      <PageSection className="tiz-band-1 pf-u-p-4xl">
        <Grid>
          <GridItem span={12} sm={10} md={8} lg={8}>

            <TextContent>
              <Title style={{ color: '#fff' }} headingLevel="h2" size="3xl" className="pf-u-mb-md">If you can imagine it we can create it</Title>
              <Text>
                Audio video, Automation and smart home systems can be complicated. Choosing the right electronics system, multi room audio, or the best in presentation technology, can be a daunting task. With Amazon, Google, Ring, Nest, wireless networks, internet services, streaming services, Netflix, Apple, Android as well as many more how do you know what is right for you and what are the differences? Whether you’re upgrading your system, remodeling your home or office, or starting from scratch with a new construction, we’ll help you figure it out. That’s our job, and we love doing it!
              </Text>
            </TextContent>

            <Button className="pf-u-mt-lg" variant="secondary" onClick={(event) => {
              event.preventDefault();
              window.location.href = 'mailto:info@tizech.com';
            }}>Contact us today</Button>

          </GridItem>
        </Grid>

      </PageSection>

    </Chrome>
  )
}

export { Dashboard };
