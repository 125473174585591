import * as React from 'react';
import { PageSection, Button, Text, TextContent, Stack, StackItem, List, ListItem, Grid, GridItem } from '@patternfly/react-core';
import { LocationArrowIcon, ExternalLinkAltIcon } from '@patternfly/react-icons';

interface IFooterProps {
  children?: React.ReactNode;
}

export const Footer: React.FunctionComponent<IFooterProps> = (props: IFooterProps) => (
  <PageSection variant="dark">
    <Grid hasGutter>

      <GridItem md={4}>
        <Stack hasGutter>
          <StackItem>

            <TextContent>
              <Text>North Carolina (Raleigh, Pinehurst, Wake Forest, Garner, Cary, Chapel Hill, Apex, Knightdale, Wendell, and Pittsboro).</Text>
              <Text>Virginia (Wintergreen, Nelson, and Amherst counties).</Text>
            </TextContent>
          </StackItem>

          <StackItem>Service when you need it!</StackItem>

        </Stack>
      </GridItem>

      <GridItem md={8}>
        <List isPlain>
          <ListItem icon={<LocationArrowIcon />}>2008 Hillsborough St., Suite 11 Raleigh, NC 27607</ListItem>
          <ListItem icon={<LocationArrowIcon />}>1900 Lowesville Rd. Amherst, VA 24521</ListItem>
          <ListItem icon={<ExternalLinkAltIcon />}>
            <Button className="tiz-cta-btn" variant="link" isInline onClick={(event) => {
                event.preventDefault();
                window.location.href = 'mailto:info@tizech.com';
              }}>info@tizech.com</Button>
          </ListItem>
        </List>
      </GridItem>

    </Grid>

    {props.children}
  </PageSection>
);
