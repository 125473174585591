import * as React from 'react';
import {
  Select,
  SelectOption,
  SelectVariant,
  SelectOptionObject,
  Form,
  FormGroup
} from '@patternfly/react-core';
interface ICategorySelectorProps {
  setCategory: React.Dispatch<React.SetStateAction<string | SelectOptionObject>>;
  isFormValid: boolean;
  onChange: (isValid: boolean, value: string | SelectOptionObject) => void;
}

export const CategorySelector: React.FunctionComponent<ICategorySelectorProps> = ({ setCategory, isFormValid, onChange }) => {
  const [singleIsOpen, setSingleIsOpen] = React.useState<boolean>(false);
  const [singleSelected, setSingleSelected] = React.useState<string | SelectOptionObject>('');
  // validation
  // const [value, setValue] = React.useState<string | SelectOptionObject>('');
  // const [isValid, setIsValid] = React.useState(false);

  const singleOptions = [
    { value: 'Choose...', disabled: false, isPlaceholder: true },
    { value: 'Networking', disabled: false },
    { value: 'Audio/video', disabled: false },
    { value: 'Electrical', disabled: false }
  ];
  const clearSelection = () => {
    setSingleIsOpen(false);
    setSingleSelected('');
  };

  const singleOnSelect = (
    event: React.MouseEvent | React.ChangeEvent,
    selection: string | SelectOptionObject,
    isPlaceholder?: boolean
  ) => {
    if (isPlaceholder) {
      clearSelection();
    } else {
      setSingleSelected(selection);
      setSingleIsOpen(false);
      setCategory(selection);

      // local validation
      const isValid = selection !== '';
      onChange && onChange(isValid, selection);
      // console.log('selected:', selection.toString());
    }
  };
  const titleId = 'title-id';
  const validated = isFormValid ? 'default' : 'error';
  return (
    <>
      <Form>
        <FormGroup
          label="Category:"
          type="text"
          helperText="Please select a category"
          helperTextInvalid="A category must be selected"
          fieldId="category"
          validated={validated}
        >
          <Select
            toggleId="single-select"
            variant={SelectVariant.single}
            aria-label="Select Input"
            onToggle={setSingleIsOpen}
            onSelect={singleOnSelect}
            selections={singleSelected}
            isOpen={singleIsOpen}
            aria-labelledby={titleId}
            direction={'down'}
            maxHeight={200}
          >
            {singleOptions.map((option, index) => (
              <SelectOption
                isDisabled={option.disabled}
                key={index}
                value={option.value}
                isPlaceholder={option.isPlaceholder}
              />
            ))}
          </Select>
        </FormGroup>
      </Form>
    </>
  )
}
