import * as React from 'react';
import {
  PageSection,
  Grid,
  GridItem,
  Title,
  Text,
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionToggle,
  TextContent
} from '@patternfly/react-core';
import electronicsTechnicianImg from '@app/assets/img/electronics-technician.jpg';

export interface IAboutUsProps {
  sampleProp?: string;
}

const AboutUs: React.FunctionComponent<IAboutUsProps> = () => {

  const [expanded, setExpanded] = React.useState('consult');

  return (
    <PageSection className="tiz-about-us">

      <Grid hasGutter>

        <GridItem md={9}>

          <Grid hasGutter>

            <GridItem md={12} lg={6}>
              <TextContent>
                <Title headingLevel="h2">A Passion for All Things Electronic</Title>
                <Text>
                  Ryan Lynch, founder of TiZECH, certified by The Custom Electronic Design &amp; Installation Association (CEDIA). He participates regularly in their continuing education programs and trade shows so he&rsquo;s always on top of the whirlwind changes in the electronics field.
                </Text>
                <Text>
                  We will help you and whomever will be using the system to understand how it work as well as captivate the audience it is serving. Our thought process from the beginning is what will be the user experience in the end. Our goal is be sure that the system we create for you will be exactly what was asked for.
                </Text>
              </TextContent>

              <Accordion className="pf-u-mt-md">
                <AccordionItem>
                  <AccordionToggle
                    isExpanded={expanded === 'consult'}
                    id="consult"
                    onClick={() => setExpanded('consult')}>Consult</AccordionToggle>
                  <AccordionContent isHidden={expanded !== 'consult'}>
                    At TiZech, we go out of our way to provide exceptional service. We&rsquo;re passionate about our work, and we&rsquo;re up to date on the latest options available. When you work with us, we&rsquo;ll take the time to listen to your needs, adhere to your budget, and install the system you&rsquo;re looking for.
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem>
                  <AccordionToggle
                    isExpanded={expanded === 'create'}
                    id="create"
                    onClick={() => setExpanded('create')}>Create</AccordionToggle>
                  <AccordionContent isHidden={expanded !== 'create'}>
                    We will create a system to meet your needs, give you room for expiation at a later point and exceed your expectations.
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem>
                  <AccordionToggle
                    isExpanded={expanded === 'captivate'}
                    id="captivate"
                    onClick={() => setExpanded('captivate')}>Captivate</AccordionToggle>
                  <AccordionContent isHidden={expanded !== 'captivate'}>
                    We will help you and whomever will be using the system to understand how it work as well as captivate the audience it is serving. Our thought process from the beginning is what will be the user experience in the end. Our goal is be sure that the system we create for you will be exactly what was asked for.
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </GridItem>

            <GridItem md={12} lg={6}>
              <TextContent>
                <Title headingLevel="h2">Ryan Has lived and Breathed Electronics All His Life.</Title>
                <Text>His childhood passion for tinkering with TVs and other gadgets turned into a successful entrepreneurial career that took him Bi-Coastal.</Text>
                <Text>With projects in other states such as Hawaii, Virginia, California and Washington Ryan has no Geographical boundaries. TiZech is a resource for all things electronic wherever you may be. His experience in different locations gave him access to nifty items and creative ideas that make things look pretty and work more effectively, but aren’t common in a specific location.</Text>

                <Title headingLevel="h2">Make a lasting impression on your customers, employees and business partners with cutting edge electronic systems</Title>
                <Text>Ryan&rsquo;s trademark personable style and sense of humor puts people at ease. But underneath the patient, understanding and easy-going exterior is a goal-oriented, forward looking professional who pays meticulous attention to detail.</Text>
                <Text>Unlike many technical experts he explains things in everyday language. No jargon here. Because of his well-sharpened ability to see the big picture of what you want, your project is completed exactly as you envisioned it.</Text>
              </TextContent>
            </GridItem>
          </Grid>

        </GridItem>

        <GridItem md={3}>
          <TextContent>
            <img style={{
              margin: '0 auto',
              display: 'block'
            }} src={electronicsTechnicianImg} alt="Raleigh electronics technician" />
            <Title headingLevel="h1">About TiZech</Title>
            <Text>If audio video installation is a mystery to you, if you don&rsquo;t yet know exactly what you want or you aren&rsquo;t sure what needs to happen, call us anyway at 919-885-0777. We&rsquo;ll help you figure it out. That&rsquo;s our job, and we love doing it!</Text>
          </TextContent>
        </GridItem>

      </Grid>

    </PageSection>
  )
}

export { AboutUs };
