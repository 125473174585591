import * as React from 'react';
import { Select, SelectOption, SelectVariant, SelectOptionObject } from '@patternfly/react-core';
interface ISubCategorySelectorProps {
  setSubcategories: React.Dispatch<React.SetStateAction<(string | SelectOptionObject)[]>>;
  category: string | SelectOptionObject;
  subCategories: (string | SelectOptionObject)[];
}

export const SubCategorySelector: React.FunctionComponent<ISubCategorySelectorProps> = ({ category, subCategories, setSubcategories }) => {

  const networkingOptions = [
    { value: 'Network', disabled: false },
    { value: 'Internet', disabled: false },
    { value: 'WiFi', disabled: false }
  ];

  const audioVideoOptions = [
    { value: 'Multi-room audio', disabled: false },
    { value: 'Home theater', disabled: false }
  ];

  const electricalOptions = [
    { value: 'Outlets', disabled: false },
    { value: 'Lights', disabled: false },
    { value: 'Fans', disabled: false },
    { value: 'Breakers', disabled: false }
  ];

  const getDefaultSubCategories = () => {
    let subCategorySet;
    switch (category) {
      case 'Networking': {
        subCategorySet = networkingOptions;
        break;
      }
      case 'Audio/video': {
        subCategorySet = audioVideoOptions;
        break;
      }
      case 'Electrical': {
        subCategorySet = electricalOptions;
        break;
      }
      default: {
        subCategorySet = [];
      }
    }
    return subCategorySet;
  }

  const [options] = React.useState(getDefaultSubCategories());
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<(string | SelectOptionObject)[]>(subCategories || []);
  const titleId = 'subcategory-selector-title';

  const onToggle = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const clearSelection = () => {
    setSelected([]);
    setIsOpen(false);
  }

  const onSelect = (
    _event: React.MouseEvent | React.ChangeEvent,
    selection: string | SelectOptionObject | (string | SelectOptionObject)[],
    isPlaceholder?: boolean
  ) => {
    let latestSelectedSubCategories: (string | SelectOptionObject)[] = [];
    if (isPlaceholder) {
      clearSelection();
    } else {
      if (selected.includes(selection)) {
        latestSelectedSubCategories = selected.filter(item => item !== selection);
        setSelected(latestSelectedSubCategories);
      } else {
        latestSelectedSubCategories = [...selected, selection];
        setSelected(latestSelectedSubCategories);
      }
      setSubcategories(latestSelectedSubCategories)
      setIsOpen(false);
    }
  };

  return (
    <>
      <span id={titleId} hidden>
        Select subcategories
      </span>
      <Select
        variant={SelectVariant.typeaheadMulti}
        typeAheadAriaLabel="Select a sub-category"
        onToggle={onToggle}
        onSelect={onSelect}
        onClear={clearSelection}
        selections={selected}
        isOpen={isOpen}
        aria-labelledby={titleId}
        placeholderText="Select a sub-category"
        isCreatable={true}
      >
        {options.map((option, index) => (
          <SelectOption isDisabled={option.disabled} key={index} value={option.value} />
        ))}
      </Select>
    </>
  )
}
